@import '../../mixins_and_variables';

.green-bar {
    padding: 6.8rem 0;
    background: $lime url('../../images/white-texture.png') center no-repeat;
    background-size: cover;

    h1 {
        @include futura(900, normal);
        font-size: 7rem;
        line-height: 7.8rem;
        text-transform: uppercase;
        text-align: center;
    }
}

.purple-box {
    background: $purple url('../../images/white-texture.png') center no-repeat;
    background-size: cover;

    svg {
        display: block;
    }

    .purple-middle {
        padding: 8rem 0;
    }
}
