@import '../../mixins_and_variables';

.input-group {
    input, select {
        &:not([type="checkbox"]) {
            background: $pale-grey;
            border: 0.2rem solid $navy-blue;
            color: $navy-blue;
        }
    }
}

.select-group {
    &:after {
        border-color: $navy-blue transparent transparent;
    }
}

.error {
    @extend .shake;
    @extend .animated;
    .select-group {
        select {
            border-color: $crimson;
            background: lighten(red, 49%);
            color: $crimson;
        }
        &:after {
            border-color: $crimson transparent transparent;
        }
    }
}
