@mixin futura($weight, $style) {
    font-family: futura-pt, sans-serif;
    font-weight: $weight;
    font-style: $style;
    //available weight(s): 600
    //available style(s): normal, italic
}

@mixin freight-sans($weight, $style) {
    font-family: freight-sans-pro, sans-serif;
    font-weight: $weight;
    font-style: $style;
    //available weight(s): 400, 700
    //available style(s): normal, italic
}

@mixin freight-text($weight, $style) {
    font-family: freight-text-pro, serif;
    font-weight: $weight;
    font-style: $style;
    //available weight(s): 400
    //available style(s): normal, italic
}

$darkblue: #112d51;
$purple: #aa4a9b;
$orange: #ffb81d;
$azure: #0092ff;
$cornflower: #6664ff;
$crimson: #cf0c2c;
$cyan: #00ffff;
$seafoam-blue: #6fcbdd;
$lime: #d8df26;
$navy-blue: #002B51;
$pale-grey: #efeff4;
$powder-blue: #cfdce6;
$steel: #66789e;
$shadow: transparentize(black, 0.8);

//Responsive Behavior
//Large smartphones (portrait view)
$screen-xs-min: 375px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

//Extra small devices
@mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}

.btn-primary {
    @include freight-sans(700, normal);
    background: $darkblue;
    border: 0.2rem solid $navy-blue;
    border-radius: 2.4rem;
    color: white;
    display: inline-block;
    font-size: 1.3rem;
    line-height: 4.4rem;
    padding: 0 3.2rem;
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: 0 0 0.8rem $shadow;
    &:hover, &:focus {
        background: $navy-blue;
        border-color: $azure;
    }
}

.btn-secondary {
    @extend .btn-primary;
    background: transparent;
    border-color: white;
}

.btn-block {
    width: 100%;
}

.text-center {
    text-align: center;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.bounceIn {
    animation-duration: 0.75s;
    animation-name: bounceIn;
}

.fadeIn {
    animation-name: fadeIn;
}

.fadeOut {
    animation-name: fadeOut;
}

.shake {
    animation-name: shake;
}

.slideInDown {
    animation-name: slideInDown;
}

.slideInLeft {
    animation-name: slideInLeft;
}

.slideInRight {
    animation-name: slideInRight;
}

.slideInUp {
    animation-name: slideInUp;
}
