@import '../../mixins_and_variables';

//Global Form Inputs
.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 0.8rem 0;
    label {
        @include futura(600, normal);
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 0 1.6rem;
        &.checkbox {
            @include freight-sans(400, normal);
            line-height: 4.8rem;
            margin: 0 0 0 0.8rem;
        }
    }
    input, select {
        &:not([type="checkbox"]) {
            @include freight-sans(400, normal);
            appearance: none;
            border-radius: 2.4rem;
            font-size: 1.6rem;
            line-height: 4.4rem;
            padding: 0 1.4rem;
            &:hover, &:focus {
                border-color: $azure;
            }
        }
        &[type="checkbox"] {
            height: 1.8rem;
            padding: 0.4rem;
            position: relative;
            margin: 0 0.4rem 0 0;
            top: 0.4rem;
            width: 1.8rem;
        }
    }
}

.select-group {
    width: 100%;
    position: relative;
    &:after {
        border-style: solid;
        border-width: 0.8rem 0.8rem 0;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        right: 1.6rem;
        top: 2rem;
        width: 0;
    }
    select {
        width: 100%;
    }
}

.date-group {
    @include md {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    label {
        flex: 0 0 100%;
    }
    .select-group {
        margin: 0 0 1.6rem 0;
        @include md {
            margin: 0 0 1.6rem 0;
            flex: 0 0 calc(33.33% - 1.6rem);
        }
    }
}
