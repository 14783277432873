@import '../../mixins_and_variables';

.smc-logo {
    height: 6.4rem;
    position: absolute;
    margin: 0.8rem 0 0 0.8rem;
    z-index: 2;
    @include md {
       height: 9.6rem;
        margin: 1.6rem 0 0 1.6rem;
    }
}

header {
    transition: height 0.6s ease;
    position: relative;
    z-index: 1;
}

.navbar {
    background-color: $darkblue;
    height: 4.8rem;
    @include md {
        height: 6.4rem;
    }
    position: relative;
    transition: height .6s ease;

    &.active {
        height: 100vh;
        .nav-toggle-bar {
            background: white;
            transform: translate3d(0,1rem,0) rotate(135deg);
            transition-delay: 75ms;
            &:before {
                opacity: 0;
                transition-delay: 0s;
            }
            &:after {
                background: white;
                transform: translate3d(0,-1.9rem,0) rotate(-270deg);
                transition-delay: 75ms;
            }
        }
    }

    .nav-toggle {
        @include md {
            display: none;
        }
        padding: 0 0.8rem;
        position: absolute;
        right: 0;
        @include md {
            padding: 0 1.6rem;
            top: 0.8rem;
        }
    }

    .nav-toggle-icon {
        display: inline-block;
        height: 2.4rem;
        margin: 0 0 0 0.8rem;
        position: relative;
        width: 3.2rem;
    }

    .nav-toggle-bar {
        @extend .toggle-bar-default;
        top: 1.4rem;
        transition-timing-function: cubic-bezier(.68,-.55,.265,1.55);
        transition-duration: .275s;
        &:after {
            @extend .toggle-bar-default;
            bottom: -1rem;
            content: "";
            display: block;
            top: 2rem;
            transition: transform .275s cubic-bezier(.68,-.55,.265,1.55);
        }
        &:before {
            @extend .toggle-bar-default;
            display: block;
            content: "";
            top: 1.1rem;
            transition: opacity .125s ease .275s;
        }
    }

    .nav-mobile {
        @include md {
            display: none;
        }
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 9.6rem 0 0 0;
        li {
            @extend .animated;
            @extend .fadeIn;
            &:nth-child(1) {
                animation-delay: 0.1s;
            }
            &:nth-child(2) {
                animation-delay: .2s;
            }
            &:nth-child(3) {
                animation-delay: .3s;
            }
            &:nth-child(4) {
                animation-delay: .4s;
            }
            &:nth-child(5) {
                animation-delay: .5s;
            }
            &:nth-child(6) {
                animation-delay: .6s;
            }
        }
    }

    a, button {
        @include futura(700, normal);
        appearance: none;
        background: none;
        border: none;
        color: white;
        display: flex;
        font-size: 1.3rem;
        line-height: 4.8rem;
        padding: 0 1.6rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;

        &[aria-haspopup="true"] {
            &:after {
                content: '';
                border: solid white;
                border-width: 0 0.4rem 0.4rem 0;
                display: inline-block;
                height: 0.4rem;
                padding: 0.4rem;
                pointer-events: none;
                position: absolute;
                right: 1.6rem;
                top: 1.2rem;
                transform: rotate(45deg);
                width: 0.4rem;
                transition: all .33s ease-in-out;
                transform-origin: 1.414rem 1.414rem;
            }
            &.open {
                &:after {
                    transform: rotate(-135deg);
                }
            }
        }
    }

    .dropdown {
        background: white;
        display: flex;
        flex-direction: column;
        min-width: 12.6rem;

        a {
            @include freight-sans(400, italic);
            color: $navy-blue;
            font-size: 1.6rem;
            line-height: 4.7rem;
            text-transform: none;
            border-bottom: 0.1rem solid $pale-grey;
            @include md {
                font-size: 2rem;
                line-height: 6.3rem;
            }

            &.active {
                color: $cornflower;
                text-decoration: underline;
            }
        }
    }

    .nav-desktop {
        display: none;
        @include md {
            display: flex;
        }

        justify-content: flex-end;

        .dropdown {
            position: absolute;
            background-color: $darkblue;
            list-style: none;

            a {
                font-size: 1.6rem;
                color: #fff;
                border: none;
            }
        }

        a, button {
            font-size: 2rem;
            line-height: 6.4rem;

            &[aria-haspopup="true"] {
                padding-right: 1.6rem;

                &:after {
                    width: 0.4rem;
                    height: 0.4rem;
                    padding: 0;
                    position: absolute;
                    right: 0.2rem;
                    top: 2.8rem;
                    border-width: 0 0.2rem 0.2rem 0;
                    transform-origin: center;
                }
            }
        }
    }
}

.sr-skip {
    position: fixed;
    top: -6.4rem;
}

.toggle-bar-default {
    background-color: white;
    display: block;
    height: 0.4rem;
    margin-top: -0.2rem;
    position: absolute;
    transition-property: transform;
    width: 3.2rem;
}
