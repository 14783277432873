@import '../../mixins_and_variables';

ul {
    list-style: none;
}

.concentration-container {
    margin-bottom: 8rem;

    &.on-campus {
        background: $seafoam-blue url('../../images/white-texture.png') center no-repeat;
        background-size: cover;
    }
    &.online {
        background: $lime url('../../images/white-texture.png') center no-repeat;
        background-size: cover;
    }

    .concentration {
        padding: 1.6rem;

        @include md {
            padding: 8rem 4rem;
        }
    }
    .concentration-wrapper {
        max-width: 76.8rem;
        margin: 0 auto;
    }
}

.concentration {
    h2 {
        @include futura(600, normal);
        @extend .text-center;
        font-size: 2rem;
        line-height: 3.2rem;
        text-transform: uppercase;
        @include md {
            font-size: 3.2rem;
            line-height: 4.8rem;
        }
    }
    h3 {
        @include freight-text(400, italic);
        @extend .text-center;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 2.4rem 0;
        text-transform: lowercase;
        @include md {
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
    p {
        @include freight-sans(400, normal);
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 2.4rem 0;
        padding: 0 1.6rem;
        @include md {
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
    .concentration-info {
        div {
            h3 {
                @include freight-sans(700, normal);
                margin: 0;
                padding: 0 1.6rem;
                text-transform: none;
                text-align: left;
            }
            ul {
                @include freight-sans(400, normal);
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin: 0 0 2.4rem 0;
                padding: 0 3.6rem;
                @include md {
                    font-size: 2rem;
                    line-height: 3.2rem;
                }
            }
            .button-container {
                h3 {
                    text-align: center;
                    padding: 0;
                    margin-bottom: 1.2rem;
                }
                button{
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
    }
}
