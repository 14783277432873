@import '../../mixins_and_variables';

footer {
    background: $seafoam-blue url('../../images/white-texture.png') center no-repeat;
    background-size: cover;

    .footer-bottom {
        padding: 8rem 0 0 0;
    }

    .footer-logo-container {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 76.8rem;
    }

    .footer-logo {
        height: 9.6rem;
    }

    .footer-container {
        margin: 0 auto;
        max-width: 76.8rem;
        padding: 0 0 3.2rem 0;
    }

    .footer-address {
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-align: center;

        @include md {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        p {
            @include freight-text(400, normal);
        }
        strong {
            @include freight-sans(700, normal);
        }
        em {
            @include freight-text(400, italic);
        }
        a {
            color: $darkblue;
            text-decoration: none;
        }
    }

    .footer-links {
        padding: 0 1.6rem 0 0;
        margin: 2.4rem 0 0 0;
        @include freight-sans(700, normal);
        text-transform: uppercase;

        div {
            text-align: center;

            @include md {
                margin-bottom: 1rem;
            }
        }

        span {
            display: block;
            margin-bottom: 0.5rem;

            @include md {
                display: inline-block;
                margin-bottom: 0;
                padding: 0 1rem;
            }
        }

        a {
            color: $darkblue;
            font-size: 1.3rem;
            text-decoration: none;
            @include md {
                font-size: 1.6rem;
            }
        }

        @include md {
            span:not(:last-child) {
                border-right: 2px #fff solid;
            }
        }
    }
}
