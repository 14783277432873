@import '../../mixins_and_variables';

.calculator-container {
    background: $orange url('../../images/white-texture.png') center no-repeat;
    background-size: cover;

    .calculator {
        padding: 1.6rem;
        @include md {
            padding: 3.2rem;
        }
    }

    .calculator-wrapper {
        max-width: 76.8rem;
        margin: 0 auto;
    }
}

.calculator {
    h2 {
        @include futura(600, normal);
        @extend .text-center;
        font-size: 2rem;
        line-height: 3.2rem;
        text-transform: uppercase;
        @include md {
            font-size: 3.2rem;
            line-height: 4.8rem;
        }
    }
    h3 {
        @include freight-text(400, italic);
        @extend .text-center;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 2.4rem 0;
        text-transform: lowercase;
        @include md {
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
    p {
        @include freight-sans(400, normal);
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0 0 2.4rem 0;
        padding: 0 1.6rem;
        @include md {
            font-size: 2rem;
            line-height: 3.2rem;
        }
    }
}

.step-indicator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    @include md {
        padding: 0 6.4rem;
    }
    > li {
        @include freight-sans(400, normal);
        font-size: 1.3rem;
        line-height: 1.6rem;
        padding: 1.6rem;
        &[aria-current="true"] {
            @include freight-sans(700, normal);
        }
    }
}

.results-container {
    .small {
        margin: 3.2rem 0 0 0;
    }
    .result {
        font-size: 2rem;
        line-height: 3.2rem;
        margin: 1.6rem 0;
        @include md {
            font-size: 3.2rem;
            line-height: 4.8rem;
        }
    }
    .scholarships {
        @include freight-sans(400, normal);
        font-size: 2rem;
        list-style: none;
        line-height: 3.2rem;
        margin: 0 0 1.6rem 0;
        p {
            margin: 0;
        }
    }
    .next-links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        padding: 1.6rem 0;
        @include md {
            flex-direction: row;
            padding: 3.2rem 0;
        }
        a {
            @extend .btn-primary;
            @extend .btn-block;
            width: calc(100% - 6.4rem);
        }
    }

    a.cyan-link {
        color: $cyan;
        &:hover {
            text-decoration: none;
        }
    }
}
