@import '../../mixins_and_variables';

.google-map {
    height: 48rem !important;
    padding: 3.2rem 0 1.6rem 0;
    position: relative !important;
    width: auto !important;
    @include md {
        height: 64rem !important;
        padding: 6.4rem 0 0 0;
    }
    .gmnoprint {
        visibility: hidden;
    }
    .gm-style-iw-c {
        padding: 0.8rem 1.6rem 0 1.6rem !important;
    }
    .gm-style-iw-d {
        h3 {
            @include futura(600, normal);
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $navy-blue;
        }
    }
    .gm-ui-hover-effect {
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: 0.4rem !important;
        right: 0 !important;
        width: 3.2rem !important;
        height: 3.2rem !important;
        text-align: center;
        img {
            height: 2.4rem !important;
            margin: 0 !important;
            width: 2.4rem !important;
        }
    }
}

.about-spartanburg-container {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;
    @include md {
        flex-direction: row;
        padding: 3.2rem;
    }
    .item {
        @include md {
            padding: 0 1.6rem;
            &:first-child {
                padding: 0 1.6rem 0 0;
            }
            &:last-child {
                padding: 0 0 0 1.6rem;
            }
        }
        h3 {
            @include futura(600, normal);
            color: $navy-blue;
            font-size: 2rem;
            line-height: 3.2rem;
            text-transform: uppercase;
        }
        p {
            @include freight-sans(400, normal);
            color: $navy-blue;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0 0 2.4rem 0;
            @include md {
                margin: 0;
            }
        }
    }
}
