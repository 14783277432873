@import '../../mixins_and_variables';

.employers-want-container {
    display: flex;
    flex-direction: column;

    @include md {
        flex-direction: row;
        justify-content: space-between;
    }

    .employers-want-left {
        padding: 4rem;

        @include md {
            width: 40%;
            padding: 8rem;
        }
    }
    .employers-want-right {
        display: flex;
        align-items: center;

        @include md {
            width: 55%;
        }
    }

    .employers-want-text {
        width: 100%;
        padding: 4rem;
        text-align: center;
        background: $lime url('../../images/white-texture.png') center no-repeat;
        background-size: cover;

        @include md {
            text-align: left;
            padding-left: calc(10% + 8rem);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
        }
    }
}
