@import '../../mixins_and_variables';

.user-form-container {
    padding: 1.6rem;
    max-width: 76.8rem;
    margin: 0 auto;
    margin-bottom: 8rem;

    @include md {
        padding: 6.4rem 1.6rem;
    }

    .user-form-text {
        text-align: center;

        h2 {
            @include futura(600, normal);
            margin-bottom: 2rem;
            color: $navy-blue;
            font-size: 2rem;
            line-height: 3.2rem;
            text-transform: uppercase;
            @include md {
                font-size: 3.2rem;
            }
        }
        p {
            @include freight-sans(400, normal);
            margin-bottom: 4rem;
            color: $navy-blue;
            font-size: 1.6rem;
            @include md {
                font-size: 2rem;
            }
            line-height: 2.4rem;
        }
    }
    .user-form-submitted {
        h2 {
            @include futura(600, normal);
            padding: 4rem 0 0 0;
            color: $navy-blue;
            font-size: 2rem;
            line-height: 3.2rem;
            text-transform: uppercase;
        }
    }
    .user-form-group {
        .input-group {
            label {
                background: white;
                color: $navy-blue;
            }
        }

        .select-group {
            &:after {
                border-color: $navy-blue transparent transparent;
            }
        }
        input, select {
            background: $pale-grey;
            border: 0.2rem solid $navy-blue;
            color: $navy-blue;
        }
    }
}
