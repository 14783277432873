@import '../../mixins_and_variables';

.part-guides-container {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    @include md {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 4rem;
    }

    .part-guides-left {
        margin-bottom: 4rem;

        @include md {
            width: 45%;
            margin-bottom: 0;
        }
    }
    .part-guides-right {
        display: flex;
        align-items: center;

        @include md {
            width: 45%;
        }
    }

    .part-guides-quote {
        @include freight-sans(400, italic);
    }
}
