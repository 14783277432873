//Typography
@import url("https://use.typekit.net/vho6sau.css");

@import 'mixins_and_variables';

html {
    font-size: 62.5%;

    * {
        margin: 0;
        padding: 0;
    }

    body {
        margin: 0;
        color: $darkblue;
    }
}

.cyan-lines {
    bottom: 1.6rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    img {
        width: 100%;
    }
    @include md {
        bottom: 3.2rem;
        left: 50%;
        margin: 0 auto;
        transform: translateX(-50%);
        width: 100%;
    }
}

.photo-hero-container {
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        object-fit: cover;
    }

    .hero-topo {
        position: absolute;
        bottom: 8rem;
        width: 100%;

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

.offset-border-white {
    display: flex;
    margin: 0;
    position: relative;

    img {
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 0;
        @include sm {
            max-height: none;
        }
    }

    &:after {
        content: '';
        width: calc(100% - 0.8rem);
        height: calc(100% - 0.8rem);
        border: 0.4rem solid white;
        position: absolute;
        top: 1rem;
        bottom: 0;
        left: 1rem;
        z-index: 1;
    }
}
.offset-border-blue {
    @extend .offset-border-white;
    &:after {
        border-color: $seafoam-blue;
    }
}
.offset-border-lime {
    @extend .offset-border-white;
    &:after {
        border-color: $lime;
    }
}
.offset-border-berry {
    @extend .offset-border-white;
    &:after {
        border-color: $purple;
    }
}
.offset-border-orange {
    @extend .offset-border-white;
    &:after {
        border-color: $orange;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.btn {
    transition: all .33s ease-in-out;
}

.container {
    max-width: 76.8rem;
    padding: 0 2rem;
    margin: 0 auto;
}

.image {
    width: 100%;

    img {
        min-width: 100%;
        max-width: 100%;
    }
}

.italic {
    font-style: italic !important;
}

.lime-slant {
    display: inline-block;
    background-color: $lime;
    padding: 0 2rem;
    clip-path: polygon(2.5% 0, 100% 0, 97.5% 100%, 0 100%);
}
.blue-slant {
    display: inline-block;
    background-color: $seafoam-blue;
    padding: 0 2rem;
    clip-path: polygon(2.5% 0, 100% 0, 97.5% 100%, 0 100%);
}
.orange-slant {
    display: inline-block;
    background-color: $orange;
    padding: 0 2rem;
    clip-path: polygon(2.5% 0, 100% 0, 97.5% 100%, 0 100%);
}

h1 {
    @include futura(700, normal);
    font-size: 2rem;
    line-height: 3.2rem;
    text-transform: uppercase;
}
h2 {
    @include futura(700, normal);
    font-size: 3rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
h3 {
    @include freight-text(400, italic);
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: lowercase;
}
h4 {
    @include futura(700, normal);
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

p, ul {
    @include freight-sans(400, normal);
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0 0.8rem 0;
    @include md {
        font-size: 2rem;
        line-height: 3.2rem;
        margin: 0 0 1.6rem 0;
    }
}

.fw-line-art {
    display: flex;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
    }
}

.topo {
    svg {
        height: 100%;
        width: 100%;
    }
}

.bg-orange {
    background-color: $orange !important;
}
.bg-berry {
    background-color: $purple !important;
}

.p-1 {
    padding: 1rem;
}
.p-3 {
    padding: 3rem;
}
.p-4 {
    padding: 4rem;
}
.p-8 {
    padding: 8rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-4 {
    margin-bottom: 4rem;
}
.mb-8 {
    margin-bottom: 8rem !important;
}
.white {
    color: #fff;
}

.display-enter {
    opacity: 0;
 }
.display-enter-active {
    opacity: 1;
    transition-delay: 1s;
    transition: opacity 1s;
}
.display-exit {
    opacity: 1;
}
.display-exit-active {
    opacity: 0;
    transition: opacity .5s;
}

.items-row {
    display: flex;
    flex-direction: column;
    margin: 0 4rem;

    @include md {
        flex-direction: row;
        justify-content: space-between;
    }

    .item {
        margin-bottom: 4rem;
        padding: 0;

        @include md {
            width: 33.33%;
            margin-bottom: 0;
            padding: 0 1.6rem;
        }

        img {
            width: 100%;
        }

        &:first-child {
            @include md {
                padding: 0 1.6rem 0 0;
            }
        }
        &:last-child {
            @include md {
                padding: 0 0 0 1.6rem;
            }
        }
        div:first-child {
            margin-bottom: 2rem;
        }
    }

    .item.stat {
        text-align: center;
        .figure {
            margin-bottom: 0;

            svg {
                height: 10rem;
                width: auto;
            }
        }

        p {
            @include freight-sans(700, normal);
        }
    }

    h3 {
        @include futura(600, normal);
        font-size: 2rem;
        line-height: 3.2rem;
        margin: 0;
        text-transform: uppercase;
    }
}

.testimonial {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    padding: 2rem;

    @include md {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 4rem;
        padding: 8rem;
    }

    background: transparent url('./images/white-texture.png') center no-repeat;
    background-size: cover;

    img {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem;

        @include md {
            margin-bottom: 0;
            margin-right: 8rem;
        }
    }

    .testimonial-name {
        @include futura(700, normal);
        font-size: 3rem;
        text-transform: uppercase;
    }

    .testimonial-class {
        @include freight-text(400, italic);
    }

    .testimonial-quote {
        @include freight-sans(700, italic);
    }
}

.paths {
    display: flex;
    flex-direction: column;

    @include md {
        flex-direction: row;
        justify-content: space-between;
    }

    & > div {
        display: flex;
        &:first-child {
            margin-bottom: 4rem;
        }

        @include md {
            width: 45%;
            &:first-child {
                margin-bottom: 0;
            }
        }
    }

    .star {
        width: 8rem;
    }

    .paths-text {
        margin-left: 2rem;
        font-size: 2rem;
        text-align: left;

        h4 {
            @include futura(700, normal);
            text-transform: uppercase;
        }
    }
}

.youtube-embed {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
